import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = [ "form", "redDot", "content" ]
    static values = {
        atomId: Number
    }

    connect() {
        super.connect()
        this.logger.debug(`Atom ID: ${this.atomIdValue}`)
    }

    disconnect() {
        super.disconnect()
    }

    toggleForm() {
        const newDisplay = this.formTarget.style.display === 'none' ? 'block' : 'none'
        this.formTarget.style.display = newDisplay
        this.logger.debug(`Form display toggled to: ${newDisplay}`)
    }

    submitReport(event) {
        event.preventDefault();
        this.logger.info("Submitting problem report")

        const form = event.target;
        const formData = new FormData(form);

        this.logger.debug("Sending fetch request for problem report")
        fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json'
            }
        })
            .then(response => {
                this.logger.debug(`Received response with status: ${response.status}`)
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    this.handleSuccessfulSubmission(data)
                } else {
                    this.handleFailedSubmission(data)
                }
            })
            .catch(error => {
                this.logger.error(`Fetch error: ${error}`)
            });
    }

    handleSuccessfulSubmission(data) {
        this.logger.info("Problem report submitted successfully")
        this.formTarget.style.display = 'none';
        this.element.querySelector('.atomic-code').classList.add('reported');
        this.redDotTarget.style.display = 'inline-block';
        this.contentTarget.textContent = data.content;

        if (data.is_new) {
            this.logger.debug("New report created")
        } else {
            this.logger.debug("Existing report updated")
        }
    }

    handleFailedSubmission(data) {
        this.logger.error(`Error submitting problem report: ${data.errors.join(', ')}`)
        alert('Error submitting problem report: ' + data.errors.join(', '));
    }
}