import BaseController from "../utils/base_controller"

export default class PlyrTimeController extends BaseController {
    static debug = false

    static targets = ["display"]
    static values = {
        debug: Boolean
    }

    connect() {
        super.connect()
        this.setupObserver()
        this.startTimeUpdate()
    }

    disconnect() {
        if (this.observer) {
            this.observer.disconnect()
        }
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        this.logger.info("Plyr Time controller disconnected")
        super.disconnect()
    }

    setupObserver() {
        let timeElement = this.element.querySelector('.plyr__time--current')

        if (timeElement) {
            this.observeTimeChanges(timeElement)
        } else {
            this.logger.debug("Time element not found, retrying in 500ms")
            setTimeout(() => this.setupObserver(), 500)
        }
    }

    observeTimeChanges(timeElement) {
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' || mutation.type === 'characterData') {
                    this.updateTime(timeElement.textContent)
                }
            })
        })

        this.observer.observe(timeElement, {
            childList: true,
            characterData: true,
            subtree: true
        })

        this.updateTime(timeElement.textContent)
        this.logger.debug("Time observer set up")
    }

    startTimeUpdate() {
        this.intervalId = setInterval(() => {
            const timeElement = this.element.querySelector('.plyr__time--current')
            if (timeElement) {
                this.updateTime(timeElement.textContent)
            }
        }, 1000)  // Update every second
        this.logger.debug("Time update interval started")
    }

    updateTime(time) {
        this.displayTarget.textContent = time || "00:00"
        this.logger.debug(`Time updated: ${time || "00:00"}`)
    }
}