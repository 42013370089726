// app/javascript/controllers/youtube/player_playback_controls_controller.js
import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["loopToggle", "speed"]

    connect() {
        this.logger.info('Connecting playback controls')
        this.isLooping = this.loopToggleTarget.checked
        this.dispatchPlaybackState()
    }
    disconnect() {
        this.logger.info('Disconnecting playback controls')
    }

    toggleLoop(event) {
        this.isLooping = event.target.checked
        this.dispatchPlaybackState()
    }

    updateSpeed(event) {
        this.dispatchPlaybackState()
    }

    dispatchPlaybackState() {
        this.logger.info('Dispatching playback update:', {
            loop: this.isLooping,
            speed: parseFloat(this.speedTarget.value)
        });
        this.dispatch('playbackUpdate', {
            detail: {
                loop: this.isLooping,
                speed: parseFloat(this.speedTarget.value)
            }
        })
    }
}