import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["uploadSection", "displayFrame"]
    static values = {
        refreshUrl: String
    }

    connect() {
        super.connect()
        this.logger.debug(`Refresh URL: ${this.refreshUrlValue}`)
        this.observeUploadSection()
    }

    disconnect() {
        if (this.observer) {
            this.observer.disconnect()
            this.logger.debug("MutationObserver disconnected")
        }
        super.disconnect()
    }

    observeUploadSection() {
        this.logger.debug("Setting up MutationObserver for upload section")
        this.observer = new MutationObserver(this.handleMutation.bind(this))
        this.observer.observe(this.uploadSectionTarget, { attributes: true, attributeFilter: ['class'] })
        this.logger.debug("MutationObserver set up successfully")
    }

    handleMutation(mutations) {
        for (const mutation of mutations) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                this.logger.debug("Class attribute changed on upload section")
                if (this.uploadSectionTarget.classList.contains('hidden')) {
                    this.logger.info("Upload section is now hidden, refreshing image")
                    this.refreshImage()
                } else {
                    this.logger.info("Upload section is now visible")
                }
            }
        }
    }

    refreshImage() {
        const refreshUrl = this.displayFrameTarget.dataset.refreshUrl

        if (refreshUrl) {
            this.logger.debug(`Attempting to refresh image with URL: ${refreshUrl}`)
            try {
                Turbo.visit(refreshUrl, { frame: this.displayFrameTarget.id, action: "replace" })
                this.logger.debug("Turbo.visit called successfully")
            } catch (error) {
                this.logger.error(`Error during reload: ${error}`)
            }
        } else {
            this.logger.error("No refresh URL found")
        }

        this.checkFrameContentAfterReload()
    }

    checkFrameContentAfterReload() {
        setTimeout(() => {
            this.logger.debug("Checking frame content after reload attempt")
            this.logger.debug(`Frame content: ${this.displayFrameTarget.innerHTML.substring(0, 100)}...`)
        }, 1000)
    }
}