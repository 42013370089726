// app/javascript/controllers/atom_unscheduling_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        initialText: String,
        confirmText: String,
        url: String     // Add this to match atom_unscheduling_controller
    }

    connect() {
        this.isConfirming = false
        this.boundResetState = this.resetState.bind(this)
    }

    toggle(event) {
        event.preventDefault()
        event.stopPropagation()

        if (!this.isConfirming) {
            this.isConfirming = true
            this.element.textContent = this.confirmTextValue
            this.element.classList.add("text-white", "bg-red-500", "hover:bg-red-600")
            document.addEventListener('click', this.boundResetState)
        } else {
            this.unschedule()
        }
    }

    async unschedule() {
        try {
            const response = await fetch(this.urlValue, {
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                }
            })

            if (response.ok) {
                const card = this.element.closest('[data-controller="animation--atom-unscheduling"]')
                card.classList.add('opacity-0', 'scale-95', 'translate-x-[-100%]')
                setTimeout(() => {
                    card.remove()
                }, 500)
            }
        } catch (error) {
            console.error('Failed to unschedule:', error)
        }
    }

    resetState(event) {
        if (!this.element.contains(event.target)) {
            this.isConfirming = false
            this.element.textContent = this.initialTextValue
            this.element.classList.remove("text-white", "bg-red-500", "hover:bg-red-600")
            document.removeEventListener('click', this.boundResetState)
        }
    }

    disconnect() {
        document.removeEventListener('click', this.boundResetState)
    }
}