import { PLAYER_CONFIG } from '../constants/player_config'
import { PLAYER_STATES } from '../constants/player_states'
import { TimeValidationService } from './time_validation_service'

export class LoopManager {
    constructor(player, logger) {
        this.player = player
        this.logger = logger
        this.isLooping = false
        this.startTime = 0
        this.endTime = 15 // Default same as controller
        this.checkInterval = null
        this.hasStartedPlaying = false
        this.timeValidator = new TimeValidationService(logger, {
            minLoopLength: PLAYER_CONFIG.MINIMUM_LOOP_LENGTH
        })
    }

    handlePlayerStateChange(state) {
        if (state === PLAYER_STATES.PLAYING) {
            this.hasStartedPlaying = true
            if (this.isLooping) {
                this.startLoopChecking()
            }
        }

        if (state === PLAYER_STATES.ENDED && this.isLooping) {
            this.seekTo(this.startTime || 0)
            setTimeout(() => this.isLooping && this.player.playVideo(), PLAYER_CONFIG.SEEK_DELAY)
        }
    }

    handlePlaybackUpdate(loop, speed) {
        this.isLooping = loop
        if (this.player) {
            this.player.setPlaybackRate(speed)
            this.isLooping ? this.startLoopChecking() : this.stopLoopChecking()
        }
    }

    setTimeRange(startTime, endTime) {
        try {
            const { startTime: validStart, endTime: validEnd } =
                this.timeValidator.validateTimeRange(startTime, endTime)

            const oldStartTime = this.startTime
            this.startTime = validStart
            this.endTime = validEnd

            // If we're currently looping and playing, and the start time has changed,
            // immediately seek to the new start position
            if (this.isLooping &&
                oldStartTime !== validStart &&
                this.player?.getPlayerState() === PLAYER_STATES.PLAYING) {
                this.seekTo(validStart)
            }

            this.logger.info('Time range set:', {
                startTime: validStart,
                endTime: validEnd,
                seeked: oldStartTime !== validStart
            })
        } catch (error) {
            this.logger.error('Invalid time range provided to loop manager:', error)
            // Don't update times if validation fails
            return false
        }
        return true
    }

    initializeLoop(isChecked) {
        this.isLooping = isChecked
        if (isChecked && !this.hasStartedPlaying) {
            try {
                // Validate current time range before starting
                this.timeValidator.validateTimeRange(this.startTime, this.endTime)

                this.player.cueVideoById({
                    videoId: this.player.getVideoData().video_id,
                    startSeconds: this.startTime
                })
            } catch (error) {
                this.logger.error('Invalid time range during loop initialization:', error)
                this.isLooping = false
                return false
            }
        }
        return true
    }

    startLoopChecking() {
        if (!this.checkInterval) {
            this.checkInterval = setInterval(() => {
                if (!this.player || !this.isLooping) return

                try {
                    // Validate time range before checking
                    this.timeValidator.validateTimeRange(this.startTime, this.endTime)

                    const currentTime = this.getCurrentTime()
                    if (currentTime >= this.endTime) {
                        this.seekTo(this.startTime)
                        setTimeout(() => {
                            if (this.isLooping) {
                                this.player.playVideo()
                            }
                        }, PLAYER_CONFIG.SEEK_DELAY)
                    }
                } catch (error) {
                    this.logger.error('Invalid time range during loop check:', error)
                    this.stopLoopChecking()
                }
            }, PLAYER_CONFIG.CHECK_INTERVALS.LOOP)
        }
    }

    stopLoopChecking() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval)
            this.checkInterval = null
        }
    }

    seekTo(time, allowSeekAhead = true) {
        if (time < 0) {
            this.logger.warn('Attempted to seek to negative time:', time)
            time = 0
        }
        this.player?.seekTo(time, allowSeekAhead)
    }

    getCurrentTime() {
        return this.player?.getCurrentTime() || 0
    }

    updateVideoDuration(duration) {
        if (duration && duration > 0) {
            this.timeValidator = new TimeValidationService(this.logger, {
                minLoopLength: PLAYER_CONFIG.MINIMUM_LOOP_LENGTH,
                videoDuration: duration
            })

            // Validate current time range with new duration
            try {
                const { startTime, endTime } =
                    this.timeValidator.adjustTimeRange(this.startTime, this.endTime)
                this.startTime = startTime
                this.endTime = endTime
            } catch (error) {
                this.logger.error('Failed to adjust times with new duration:', error)
                this.stopLoopChecking()
            }
        }
    }

    cleanup() {
        this.stopLoopChecking()
        this.timeValidator = null
    }
}