import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["form", "reportingPeriod", "specificDate"]

    connect() {
        super.connect()
        this.boundHandleChange = this.handleChange.bind(this)
        this.element.addEventListener("change", this.boundHandleChange)
    }

    disconnect() {
        this.element.removeEventListener("change", this.boundHandleChange)
        super.disconnect()
    }

    handleChange(event) {
        this.logger.debug(`Change event triggered on ${event.target.name || 'unnamed element'}`)

        if (this.hasReportingPeriodTarget && this.hasSpecificDateTarget &&
            event.target === this.reportingPeriodTarget) {
            this.specificDateTarget.value = ''
            this.logger.debug("Specific date value cleared due to reporting period change")
        }

        this.submitForm(event)
    }

    submitForm(event) {
        const form = this.findForm()
        if (form) {
            this.logger.debug("Form found, attempting to submit")
            if (typeof form.requestSubmit === 'function') {
                form.requestSubmit()
                this.logger.info("Form submitted using requestSubmit()")
            } else {
                form.submit()
                this.logger.info("Form submitted using submit()")
            }
        } else {
            this.logger.error("Form not found for submit--on-change controller")
        }
    }

    findForm() {
        if (this.hasFormTarget) {
            this.logger.debug("Form found via form target")
            return this.formTarget
        }
        if (this.element.tagName === 'FORM') {
            this.logger.debug("Controller element is the form")
            return this.element
        }
        const closestForm = this.element.closest('form')
        if (closestForm) {
            this.logger.debug("Form found via closest parent")
        } else {
            this.logger.warn("No form found")
        }
        return closestForm
    }
}