import BaseController from "../utils/base_controller"
import { EVENTS } from './constants/events'

export default class extends BaseController {
    static debug = false

    static values = {
        atomId: String,
        atomType: String,
        videoId: String,
        url: String,
        loadPath: String,
        savePath: String
    }

    connect() {
        this.logger.info('Player Settings controller connecting', {
            atomId: this.atomIdValue,
            atomType: this.atomTypeValue,
            videoId: this.videoIdValue,
            loadPath: this.loadPathValue,
            savePath: this.savePathValue,
            url: this.urlValue
        })
        this.initializeLoadFeatures()
        this.initializeSaveFeatures()
    }

    // Common utility methods
    findVideoData(settings) {
        if (!settings?.youtube_urls) return null

        return settings.youtube_urls.find(data => {
            const videoIdMatch = data.url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i)
            return videoIdMatch && videoIdMatch[1] === this.videoIdValue
        })
    }

    dispatchSettingsUpdate(settings) {
        this.dispatch(EVENTS.SETTINGS_UPDATE, {
            detail: { settings }
        })
    }

    // Settings Loading Related Methods
    async initializeLoadFeatures() {
        this.logger.info('Initializing settings loader')
        await this.loadSettings()
    }

    async loadSettings() {
        if (!this.hasLoadPathValue) {
            this.logger.info('No load path available')
            return
        }

        this.logger.info('Loading settings from path:', this.loadPathValue)

        try {
            const response = await fetch(this.loadPathValue)
            if (!response.ok) throw new Error('Failed to load settings')

            const settings = await response.json()
            this.logger.info('Loaded settings:', settings)

            const videoData = this.findVideoData(settings)
            this.logger.info('Found video data:', videoData)

            if (videoData) {
                this.logger.info('Dispatching settings update with:', videoData)
                this.dispatchSettingsUpdate(videoData)
            } else {
                this.logger.info('No video data found for current video')
            }
        } catch (error) {
            this.logger.error('Error loading settings:', error)
        }
    }

    // Settings Saving Related Methods
    initializeSaveFeatures() {
        this.bindSaveEvents()
    }

    bindSaveEvents() {
        this.element.addEventListener(EVENTS.VOLUME_CHANGED, this.handleVolumeChanged.bind(this))
        this.element.addEventListener(EVENTS.TIME_UPDATE, this.handleTimeUpdate.bind(this))
        this.element.addEventListener(EVENTS.PLAYBACK_UPDATE, this.handlePlaybackUpdate.bind(this))
    }

    async handleTimeUpdate(event) {
        this.logger.info('Time update:', event.detail)
        const { startTime, endTime } = event.detail
        await this.saveSettings({
            video_id: this.videoIdValue,
            url: this.hasUrlValue ? this.urlValue : undefined,
            start_minutes: Math.floor(startTime / 60),
            start_seconds: startTime % 60,
            end_minutes: Math.floor(endTime / 60),
            end_seconds: endTime % 60
        })
    }

    async handlePlaybackUpdate(event) {
        this.logger.info('Playback update:', event.detail)
        const { loop, speed } = event.detail
        await this.saveSettings({
            video_id: this.videoIdValue,
            url: this.hasUrlValue ? this.urlValue : undefined,
            loop,
            playback_speed: speed
        })
    }

    async handleVolumeChanged(event) {
        this.logger.info('Volume update:', event.detail.volume)
        await this.saveSettings({
            video_id: this.videoIdValue,
            url: this.hasUrlValue ? this.urlValue : undefined,
            volume: event.detail.volume
        })
    }

    async saveSettings(settings) {
        if (!this.hasSavePathValue) {
            this.logger.info('No save path available:', this.savePathValue)
            return
        }

        this.logger.info('Saving settings:', settings)

        try {
            const token = document.querySelector('[name="csrf-token"]')?.content
            if (!token) {
                this.logger.error('CSRF token not found')
                return
            }

            const response = await fetch(this.savePathValue, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': token
                },
                body: JSON.stringify({ youtube_setting: settings })
            })

            if (!response.ok) {
                throw new Error(`Failed to save settings: ${response.status}`)
            }

            this.logger.info('Settings saved successfully')
        } catch (error) {
            this.logger.error('Error saving settings:', error)
        }
    }

    disconnect() {
        // Clean up event listeners
        this.element.removeEventListener(EVENTS.VOLUME_CHANGED, this.handleVolumeChanged.bind(this))
        this.element.removeEventListener(EVENTS.TIME_UPDATE, this.handleTimeUpdate.bind(this))
        this.element.removeEventListener(EVENTS.PLAYBACK_UPDATE, this.handlePlaybackUpdate.bind(this))
    }
}