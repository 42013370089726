import YouTubeBaseController from "./youtube_base_controller"
import { EVENTS } from './constants/events'
import { VolumeManager } from './services/volume_manager'
import { LoopManager } from './services/loop_manager'
import { TimeManager } from './services/time_manager'

export default class extends YouTubeBaseController {
    static debug = false
    static targets = ["player"]

    player = null
    volumeManager = null
    loopManager = null
    timeManager = null

    constructor(context) {
        super(context)
        if (!window._ytInitCount) window._ytInitCount = 0
        this.instanceId = ++window._ytInitCount

        // Bind event handlers
        this.handlePlayerReady = this.handlePlayerReady.bind(this)
        this.handlePlayerStateChange = this.handlePlayerStateChange.bind(this)
        this.handlePlaybackUpdate = this.handlePlaybackUpdate.bind(this)
        this.handleTimeUpdate = this.handleTimeUpdate.bind(this)
        this.handleSettingsUpdate = this.handleSettingsUpdate.bind(this)
    }

    async connect() {
        super.connect()
        this.logger.info(`Connecting core player:`, this.videoIdValue)

        try {
            if (this.hasPlayerTarget) {
                this.player = await this.initializeYouTubePlayer(this.playerTarget, {
                    events: {
                        onReady: this.handlePlayerReady,
                        onStateChange: this.handlePlayerStateChange,
                        onVolumeChange: () => this.volumeManager?.handleVolumeChange()
                    }
                })

                // Initialize managers
                this.timeManager = new TimeManager(this.player, this.logger)
                const { startTime, endTime } = this.timeManager.initialize(this.element)

                this.volumeManager = new VolumeManager(
                    this.player,
                    this.logger,
                    this.dispatch.bind(this)
                )

                this.loopManager = new LoopManager(
                    this.player,
                    this.logger
                )

                // Set initial time range for loop manager
                this.loopManager.setTimeRange(startTime, endTime)
            }
        } catch (error) {
            this.logger.error('Failed to initialize YouTube player:', error)
        }

        this.bindEvents()
    }

    bindEvents() {
        this.element.addEventListener(EVENTS.PLAYBACK_UPDATE, this.handlePlaybackUpdate)
        this.element.addEventListener(EVENTS.TIME_UPDATE, this.handleTimeUpdate)
        this.element.addEventListener(EVENTS.SETTINGS_UPDATE, this.handleSettingsUpdate)
    }

    handlePlayerReady(event) {
        this.logger.info('Player ready')

        // Initialize managers
        this.volumeManager.initialize()
        this.timeManager.handlePlayerReady()

        // Initialize playback controls
        this.initializeControls()

        // Dispatch ready event
        this.dispatch(EVENTS.PLAYER_READY, { detail: { player: this.player } })
    }

    initializeControls() {
        const speedControl = this.element.querySelector('[data-youtube--player-playback-controls-target="speed"]')
        if (speedControl) {
            this.player.setPlaybackRate(parseFloat(speedControl.value))
        }

        const loopControl = this.element.querySelector('[data-youtube--player-playback-controls-target="loopToggle"]')
        if (loopControl) {
            this.loopManager.initializeLoop(loopControl.checked)
        }
    }

    handlePlayerStateChange(event) {
        this.loopManager.handlePlayerStateChange(event.data)
        this.dispatch(EVENTS.STATE_CHANGE, { detail: { player: this.player, state: event.data } })
    }

    handlePlaybackUpdate(event) {
        this.loopManager.handlePlaybackUpdate(event.detail.loop, event.detail.speed)
    }

    handleTimeUpdate(event) {
        const { startTime, endTime } = event.detail;
        this.logger.info('Time update received:', { startTime, endTime });

        // Let TimeManager adjust the times first
        this.timeManager.handleTimeUpdate(startTime, endTime);

        // Get the adjusted times
        const adjustedTimes = this.timeManager.getCurrentTimes();

        // Use the adjusted times for the LoopManager
        this.loopManager.setTimeRange(adjustedTimes.startTime, adjustedTimes.endTime);

        this.logger.info('After time update:', {
            timeManager: adjustedTimes,
            currentTime: this.player.getCurrentTime(),
            duration: this.player.getDuration()
        });
    }
    handleSettingsUpdate(event) {
        const { settings } = event.detail
        this.volumeManager.applySettings(settings)
    }

    disconnect() {
        // Cleanup managers
        this.loopManager?.cleanup()
        this.volumeManager?.cleanup()
        this.timeManager?.cleanup()

        // Destroy player
        if (this.player) {
            this.player.destroy()
            this.player = null
        }

        // Remove event listeners
        this.element.removeEventListener(EVENTS.PLAYBACK_UPDATE, this.handlePlaybackUpdate)
        this.element.removeEventListener(EVENTS.TIME_UPDATE, this.handleTimeUpdate)
        this.element.removeEventListener(EVENTS.SETTINGS_UPDATE, this.handleSettingsUpdate)

        this.volumeManager = null;
        this.loopManager = null;
        this.timeManager = null;

        super.disconnect()
    }
}