import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = [
        "container",
        "notes",
        "video",
        "sound"
    ]
    connect() {
        super.connect()
        this.initializeController()
    }

    disconnect() {
        super.disconnect()
    }

    initializeController() {
        if (this.hasContainerTarget) {
            this.hideAll()
            this.updateButtonStates(null)
            this.updateContainerVisibility()
        } else {
            this.logger.warn("Container target not found. Some functionality may be limited.")
        }
    }

    toggleSound(event) {
        event.preventDefault()
        this.toggleSection("sound")
    }

    toggleNotes(event) {
        event.preventDefault()
        this.toggleSection("notes")
    }

    toggleVideo(event) {
        event.preventDefault()
        this.toggleSection("video")
    }

    toggleSection(sectionName) {
        if (!this.hasContainerTarget) {
            this.logger.warn(`Cannot toggle ${sectionName} section: container not found`)
            return
        }

        const isVisible = this.containerTarget.classList.contains(`${sectionName}-visible`)
        this.hideAll()
        this.updateButtonStates(null)

        if (!isVisible) {
            this.containerTarget.classList.add(`${sectionName}-visible`)
            this.updateButtonStates(sectionName)
        }

        this.updateContainerVisibility()
    }

    hideAll() {
        if (!this.hasContainerTarget) return
        this.containerTarget.classList.remove("notes-visible", "video-visible", "sound-visible")
    }

    updateButtonStates(activeView) {
        const targetNames = ["sound", "notes", "video"]

        targetNames.forEach(target => {
            this[`${target}Targets`].forEach(element => {
                element.dataset.state = target === activeView ? "active" : "inactive"
            })
        })
    }

    updateContainerVisibility() {
        if (!this.hasContainerTarget) return

        const hasVisibleContent = ["notes", "video", "sound"].some(section =>
            this.containerTarget.classList.contains(`${section}-visible`)
        )

        this.containerTarget.classList.toggle("no-content-visible", !hasVisibleContent)
    }
}