import BaseController from "../utils/base_controller"

export default class LoadingAnimationBasicLinkClick extends BaseController {
    static targets = ["link"]
    static values = {
        spinnerId: { type: String, default: 'global-spinner' },
        deactivateDelay: { type: Number, default: 200 },
        timeout: { type: Number, default: 20000 }
    }

    connect() {
        super.connect()
        this.logger.debug(`Spinner ID: ${this.spinnerIdValue}`)
        this.spinnerTimeout = null
        this.bindEvents()
        this.disableInstantClickForNonTurboLinks()
    }

    bindEvents() {
        this.handleClick = this.handleClick.bind(this)
        this.onPageLoad = this.onPageLoad.bind(this)

        this.linkTargets.forEach(link => {
            link.addEventListener('click', this.handleClick)
        })
    }

    unbindEvents() {
        this.linkTargets.forEach(link => {
            link.removeEventListener('click', this.handleClick)
        })
        window.removeEventListener('load', this.onPageLoad)
    }

    disableInstantClickForNonTurboLinks() {
        this.linkTargets.forEach(link => {
            if (link.getAttribute('data-turbo') === 'false') {
                link.setAttribute('data-no-instant', '')
            }
        })
    }

    enableInstantClickForNonTurboLinks() {
        this.linkTargets.forEach(link => {
            if (link.getAttribute('data-turbo') === 'false') {
                link.removeAttribute('data-no-instant')
            }
        })
    }

    handleClick(event) {
        const link = event.currentTarget

        if (event.ctrlKey || event.metaKey || link.target === '_blank') {
            this.logger.debug('Click ignored: new tab/window')
            return
        }

        if (link.getAttribute('data-turbo') === 'false') {
            this.logger.debug('Non-Turbo link clicked, activating spinner')
            this.activateSpinner()
        } else {
            this.logger.debug('Turbo-enabled link clicked, not activating spinner')
            return
        }

        this.spinnerTimeout = setTimeout(() => {
            this.logger.debug('Page load timeout, deactivating spinner')
            this.deactivateSpinner()
        }, this.timeoutValue)

        window.addEventListener('load', this.onPageLoad, { once: true })
    }

    onPageLoad() {
        this.logger.debug('Page loaded, deactivating spinner')
        this.deactivateSpinnerWithDelay()
    }

    activateSpinner() {
        this.logger.debug('activateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.add('active')
                this.logger.debug("Spinner activated")
            } else {
                this.logger.error("'.atom-spinner' element not found inside the spinner wrapper")
            }
        } else {
            this.logger.error(`Spinner wrapper with id '${this.spinnerIdValue}' not found`)
        }
    }

    deactivateSpinner() {
        this.logger.debug('deactivateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.remove('active')
                this.logger.debug("Spinner deactivated")
            } else {
                this.logger.error("'.atom-spinner' element not found inside the spinner wrapper")
            }
        } else {
            this.logger.error(`Spinner wrapper with id '${this.spinnerIdValue}' not found`)
        }
    }

    deactivateSpinnerWithDelay() {
        this.logger.debug(`Deactivating spinner with delay of ${this.deactivateDelayValue}ms`)
        clearTimeout(this.deactivateTimeout)
        this.deactivateTimeout = setTimeout(() => {
            this.deactivateSpinner()
        }, this.deactivateDelayValue)
    }

    getSpinnerElement() {
        if (!this.spinnerIdValue) {
            this.logger.error("Spinner ID is not set")
            return null
        }
        const spinner = document.getElementById(this.spinnerIdValue)
        if (!spinner) {
            this.logger.error(`Spinner element with id '${this.spinnerIdValue}' not found in the DOM`)
        }
        return spinner
    }

    clearTimeout() {
        if (this.spinnerTimeout) {
            clearTimeout(this.spinnerTimeout)
            this.spinnerTimeout = null
        }
    }
}