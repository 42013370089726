import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["cloud", "tagsInput"]
    static values = {
        spinnerId: String,
        frameId: String
    }

    connect() {
        super.connect()
        this.selectedTags = new Set(this.getInitialTags())
        this.updateTagAppearance()
        this.boundHideSpinner = this.hideSpinner.bind(this)
        this.logger.debug("Tag cloud initialized with tags: " + Array.from(this.selectedTags).join(', '))
    }

    disconnect() {
        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.removeEventListener('turbo:frame-render', this.boundHideSpinner)
        }
        this.logger.debug("Tag cloud controller disconnected")
        super.disconnect()
    }

    getInitialTags() {
        if (this.hasTagsInputTarget) {
            return this.tagsInputTarget.value.split(',').filter(Boolean)
        } else {
            this.logger.warn("tagsInput target not found, initializing with empty set")
            return []
        }
    }

    toggleTag(event) {
        event.preventDefault()
        const tag = event.currentTarget.dataset.tag
        if (this.selectedTags.has(tag)) {
            this.selectedTags.delete(tag)
            this.logger.debug(`Tag removed: ${tag}`)
        } else {
            this.selectedTags.add(tag)
            this.logger.debug(`Tag added: ${tag}`)
        }
        this.updateTagAppearance()
        this.updateSelectedTags()
        this.submit()
    }

    updateTagAppearance() {
        if (this.hasCloudTarget) {
            this.cloudTarget.querySelectorAll('.tag').forEach(tagElement => {
                const tag = tagElement.dataset.tag
                if (this.selectedTags.has(tag)) {
                    tagElement.classList.add('selected')
                } else {
                    tagElement.classList.remove('selected')
                }
            })
            this.logger.debug("Tag appearance updated")
        } else {
            this.logger.warn("Cloud target not found, unable to update tag appearance")
        }
    }

    updateSelectedTags() {
        if (this.hasTagsInputTarget) {
            this.tagsInputTarget.value = Array.from(this.selectedTags).join(',')
            this.logger.debug("Selected tags updated: " + this.tagsInputTarget.value)
        } else {
            this.logger.warn("tagsInput target not found, unable to update selected tags")
        }
    }

    submit() {
        this.showSpinner()
        if (this.element.tagName === 'FORM') {
            this.element.requestSubmit()
        } else {
            const form = this.element.closest('form')
            if (form) {
                form.requestSubmit()
            } else {
                this.logger.error("No form found to submit")
            }
        }
        this.logger.info("Form submitted")
    }

    reset(event) {
        event.preventDefault()
        this.selectedTags.clear()
        this.updateTagAppearance()
        this.updateSelectedTags()
        if (this.element.tagName === 'FORM') {
            this.element.reset()
        } else {
            const form = this.element.closest('form')
            if (form) {
                form.reset()
            }
        }
        this.submit()
        this.logger.info("Tag cloud reset")
    }

    showSpinner() {
        const spinner = document.getElementById(this.spinnerIdValue)
        if (spinner) {
            spinner.classList.remove('hidden')
            this.logger.debug("Spinner shown")
        } else {
            this.logger.warn(`Spinner with id '${this.spinnerIdValue}' not found`)
        }
        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.addEventListener('turbo:frame-render', this.boundHideSpinner, { once: true })
        } else {
            this.logger.warn(`Frame with id '${this.frameIdValue}' not found`)
        }
    }

    hideSpinner() {
        const spinner = document.getElementById(this.spinnerIdValue)
        if (spinner) {
            spinner.classList.add('hidden')
            this.logger.debug("Spinner hidden")
        } else {
            this.logger.warn(`Spinner with id '${this.spinnerIdValue}' not found`)
        }
    }
}