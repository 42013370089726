// app/javascript/controllers/youtube/player_time_controls_controller.js
import BaseController from "../utils/base_controller"
import { TimeValidationService } from "./services/time_validation_service"

export default class extends BaseController {
    static targets = ["startMinutes", "startSeconds", "endMinutes", "endSeconds"]

    connect() {
        this.logger.info('Connecting time controls')
        this.playerController = this.element.closest('[data-controller="youtube--single-player-core"]')
            ?.getAttribute('data-youtube--single-player-core')

        // Initialize validation service
        this.timeValidator = new TimeValidationService(this.logger)
    }

    disconnect() {
        this.logger.info('Disconnecting time controls')
        this.timeValidator = null
    }

    calculateStartTime() {
        const minutes = parseInt(this.startMinutesTarget.value) || 0
        const seconds = parseInt(this.startSecondsTarget.value) || 0
        const startTime = (minutes * 60) + seconds
        this.logger.info('Calculated start time:', {
            minutes,
            seconds,
            startTime,
            rawMinutesValue: this.startMinutesTarget.value,
            rawSecondsValue: this.startSecondsTarget.value
        })
        return startTime
    }

    calculateEndTime() {
        const minutes = parseInt(this.endMinutesTarget.value) || 0
        const seconds = parseInt(this.endSecondsTarget.value) || 0
        return (minutes * 60) + seconds
    }

    updateTime() {
        const startTime = this.calculateStartTime()
        const endTime = this.calculateEndTime()

        try {
            // Try to get valid times, will throw if impossible to adjust
            const { startTime: validStart, endTime: validEnd } =
                this.timeValidator.adjustTimeRange(startTime, endTime)

            // If times were adjusted, update the UI first
            if (validStart !== startTime || validEnd !== endTime) {
                this.updateUIControls(validStart, validEnd)
            }

            // Dispatch the validated times
            this.dispatch('timeUpdate', {
                detail: {
                    startTime: validStart,
                    endTime: validEnd
                }
            })
        } catch (error) {
            this.logger.error('Invalid time range:', error)
            // Optionally show error to user
            // Reset to previous valid state if needed
        }
    }

    updateUIControls(startTime, endTime) {
        // Update minutes
        this.startMinutesTarget.value = Math.floor(startTime / 60)
        this.endMinutesTarget.value = Math.floor(endTime / 60)

        // Update seconds
        this.startSecondsTarget.value = Math.floor(startTime % 60)
        this.endSecondsTarget.value = Math.floor(endTime % 60)
    }
}