import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static debug = false

    connect() {
        super.connect()

        this.boundHandleMouseOver = this.handleMouseOver.bind(this)
        this.boundHandleMouseOut = this.handleMouseOut.bind(this)

        this.element.addEventListener('mouseover', this.boundHandleMouseOver)
        this.element.addEventListener('mouseout', this.boundHandleMouseOut)

        this.logger.debug(`Initial z-index: ${this.element.style.zIndex || 'not set'}`)
    }

    disconnect() {
        this.element.removeEventListener('mouseover', this.boundHandleMouseOver)
        this.element.removeEventListener('mouseout', this.boundHandleMouseOut)

        super.disconnect()
    }

    handleMouseOver() {
        this.element.style.zIndex = '2'
        this.logger.debug("Mouse over: z-index set to 2")
    }

    handleMouseOut() {
        this.element.style.zIndex = '0'
        this.logger.debug("Mouse out: z-index reset to 0")
    }
}