import BaseController from "../utils/base_controller"

export default class LoadingAnimationTurboClick extends BaseController {
    static values = {
        spinnerId: { type: String, default: 'global-spinner' },
        deactivateDelay: { type: Number, default: 200 },
    }

    connect() {
        super.connect()
        this.logger.debug("LoadingAnimationTurboClick Controller connected")
        this.logger.debug(`Spinner ID: ${this.spinnerIdValue}`)
        this.bindEvents()
    }

    disconnect() {
        this.unbindEvents()
        this.logger.debug("LoadingAnimationTurboClick Controller disconnected")
        super.disconnect()
    }

    bindEvents() {
        this.handleBeforeFetchRequest = this.handleBeforeFetchRequest.bind(this)
        this.handleFetchRequestError = this.handleFetchRequestError.bind(this)
        this.handleBeforeRender = this.handleBeforeRender.bind(this)
        this.handleRender = this.handleRender.bind(this)
        this.handleFrameRender = this.handleFrameRender.bind(this)
        this.handleBeforeFetchResponse = this.handleBeforeFetchResponse.bind(this)
        this.handleSubmitEnd = this.handleSubmitEnd.bind(this)

        document.addEventListener("turbo:before-fetch-request", this.handleBeforeFetchRequest)
        document.addEventListener("turbo:fetch-request-error", this.handleFetchRequestError)
        document.addEventListener("turbo:before-render", this.handleBeforeRender)
        document.addEventListener("turbo:render", this.handleRender)
        document.addEventListener("turbo:frame-render", this.handleFrameRender)
        document.addEventListener("turbo:before-fetch-response", this.handleBeforeFetchResponse)
        document.addEventListener("turbo:submit-end", this.handleSubmitEnd)
    }

    unbindEvents() {
        document.removeEventListener("turbo:before-fetch-request", this.handleBeforeFetchRequest)
        document.removeEventListener("turbo:fetch-request-error", this.handleFetchRequestError)
        document.removeEventListener("turbo:before-render", this.handleBeforeRender)
        document.removeEventListener("turbo:render", this.handleRender)
        document.removeEventListener("turbo:frame-render", this.handleFrameRender)
        document.removeEventListener("turbo:before-fetch-response", this.handleBeforeFetchResponse)
        document.removeEventListener("turbo:submit-end", this.handleSubmitEnd)
    }

    handleBeforeFetchRequest(event) {
        if (event.detail.fetchOptions.headers['Turbo-Frame'] &&
            event.target.hasAttribute('data-no-instant')) {
            this.logger.debug('Ignoring fetch for data-no-instant link')
            return
        }

        this.logger.debug('Turbo fetch request started, activating spinner')
        this.activateSpinner()
    }

    handleFetchRequestError(event) {
        this.logger.debug('Turbo fetch request error, deactivating spinner')
        this.deactivateSpinner()
    }

    handleBeforeRender(event) {
        this.logger.debug('Turbo before render, preparing to deactivate spinner')
    }

    handleRender(event) {
        this.logger.debug('Turbo render complete, deactivating spinner')
        this.deactivateSpinnerWithDelay()
    }

    handleFrameRender(event) {
        this.logger.debug('Turbo frame render complete, deactivating spinner')
        this.deactivateSpinnerWithDelay()
    }

    handleBeforeFetchResponse(event) {
        this.logger.debug('Turbo before fetch response, preparing to deactivate spinner')
        if (event.detail.fetchResponse.succeeded) {
            this.deactivateSpinnerWithDelay()
        } else {
            this.logger.debug('Fetch response failed, deactivating spinner immediately')
            this.deactivateSpinner()
        }
    }

    handleSubmitEnd(event) {
        this.logger.debug('Turbo submit end, deactivating spinner')
        this.deactivateSpinner()
    }

    activateSpinner() {
        this.logger.debug('activateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.add('active')
                this.logger.debug("Spinner activated")
            } else {
                this.logger.error("'.atom-spinner' element not found inside the spinner wrapper")
            }
        } else {
            this.logger.error(`Spinner wrapper with id '${this.spinnerIdValue}' not found`)
        }
    }

    deactivateSpinner() {
        this.logger.debug('deactivateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.remove('active')
                this.logger.debug("Spinner deactivated")
            } else {
                this.logger.error("'.atom-spinner' element not found inside the spinner wrapper")
            }
        } else {
            this.logger.error(`Spinner wrapper with id '${this.spinnerIdValue}' not found`)
        }
    }

    deactivateSpinnerWithDelay() {
        this.logger.debug(`Deactivating spinner with delay of ${this.deactivateDelayValue}ms`)
        clearTimeout(this.deactivateTimeout)
        this.deactivateTimeout = setTimeout(() => {
            this.deactivateSpinner()
        }, this.deactivateDelayValue)
    }

    getSpinnerElement() {
        if (!this.spinnerIdValue) {
            this.logger.error("Spinner ID is not set")
            return null
        }
        const spinner = document.getElementById(this.spinnerIdValue)
        if (!spinner) {
            this.logger.error(`Spinner element with id '${this.spinnerIdValue}' not found in the DOM`)
        }
        return spinner
    }
}