export const EVENTS = {
    // Events found in player_load_save_settings_controller.js
    VOLUME_CHANGED: 'youtube--player-settings-save:volumeChanged',
    TIME_UPDATE: 'youtube--player-time-controls:timeUpdate',
    PLAYBACK_UPDATE: 'youtube--player-playback-controls:playbackUpdate',
    SETTINGS_UPDATE: 'youtube--player-settings-load:settingsUpdate',

    // Events found in multi_player_switcher_controller.js
    VIDEO_SWITCH: 'videoSwitch',

    // Events found in single_player_core_controller.js
    PLAYER_READY: 'ready',
    STATE_CHANGE: 'stateChange'
}