// app/javascript/controllers/buttons/click_animation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        animation: String
    }

    click() {
        this.element.classList.add(this.animationValue)
    }
}