// app/javascript/src/services/youtube_api.js
export default class YouTubeAPI {
    static initializationPromise = null;

    static initialize() {
        if (this.initializationPromise) return this.initializationPromise;

        this.initializationPromise = new Promise((resolve) => {
            if (window.YT && !window.YT.loading) {
                resolve();
                return;
            }

            if (window.YT && window.YT.loading) {
                const checkYT = setInterval(() => {
                    if (!window.YT.loading) {
                        clearInterval(checkYT);
                        resolve();
                    }
                }, 100);
                return;
            }

            window.onYouTubeIframeAPIReady = () => {
                resolve();
            };

            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        });

        return this.initializationPromise;
    }

    static get isInitialized() {
        return window.YT && !window.YT.loading;
    }
}