import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["input", "tagList", "hiddenInput"]

    connect() {
        super.connect()
        this.tags = new Set(this.hiddenInputTarget.value.split(',').map(tag => tag.trim()).filter(tag => tag.length > 0))
        this.renderTags()
        this.boundHandleKeydown = this.handleKeydown.bind(this)
        this.inputTarget.addEventListener('keydown', this.boundHandleKeydown)
        this.logger.debug("Tag input initialized with tags: " + Array.from(this.tags).join(', '))
    }

    disconnect() {
        this.inputTarget.removeEventListener('keydown', this.boundHandleKeydown)
        this.logger.debug("Tag input controller disconnected")
        super.disconnect()
    }

    handleKeydown(event) {
        if (event.key === "Enter") {
            event.preventDefault()
            const tag = this.inputTarget.value.trim()

            if (tag) {
                this.addTag(tag)
            } else {
                this.saveForm()
            }
        }
    }

    addTag(tag) {
        if (!this.tags.has(tag)) {
            this.tags.add(tag)
            this.renderTags()
            this.inputTarget.value = ""
            this.logger.debug(`Tag added: ${tag}`)
        } else {
            this.logger.warn(`Tag "${tag}" already exists`)
        }
    }

    removeTag(event) {
        const tag = event.target.dataset.tag
        if (this.tags.delete(tag)) {
            this.renderTags()
            this.logger.debug(`Tag removed: ${tag}`)
        } else {
            this.logger.warn(`Attempted to remove non-existent tag: ${tag}`)
        }
    }

    renderTags() {
        this.tagListTarget.innerHTML = Array.from(this.tags).map(tag =>
            `<span class="tag">${tag} <button data-action="click->tag-input#removeTag" data-tag="${tag}">&times;</button></span>`
        ).join('')
        this.hiddenInputTarget.value = Array.from(this.tags).join(',')
        this.logger.debug("Tags rendered and hidden input updated")
    }

    saveForm() {
        const form = this.element.closest('form')
        if (form) {
            form.requestSubmit()
            this.logger.info("Form submitted")
        } else {
            this.logger.error("No form found to submit")
        }
    }
}