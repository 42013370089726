import { Controller } from "@hotwired/stimulus"
import BaseController from "../utils/base_controller";

export default class extends BaseController {
    static targets = [ "container", "settingOptions", "metronomeTimer", "guitarTuner", "artificialIntelligence" ]
    static values = {
        pinnedMetronome: Boolean
    }

    connect() {
        super.connect()
        this.activeTools = new Set()
        this.initializeController()
        this.logger.debug(`Initial active tools: ${Array.from(this.activeTools).join(', ')}`)
    }

    disconnect() {
        this.logger.debug(`Final active tools: ${Array.from(this.activeTools).join(', ')}`)
        super.disconnect()
    }

    initializeController() {
        if (this.hasPinnedMetronomeValue && this.pinnedMetronomeValue) {
            this.activeTools.add("metronome")
            this.containerTarget.classList.add("metronome-visible")
            this.activateButton("metronome")
            this.containerTarget.classList.add('tool-visible')
        } else {
            this.hideAllTools()
            this.deactivateAllButtons()
            this.updateContainerVisibility()
        }
    }

    toggleSettingOptions() {
        this.toggleTool("settings", this.settingOptionsTarget)
    }

    toggleMetronomeTimer() {
        if (this.hasPinnedMetronomeValue && this.pinnedMetronomeValue &&
            this.containerTarget.classList.contains("metronome-visible")) {
            return
        }
        this.toggleTool("metronome", this.metronomeTimerTarget)
    }

    toggleArtificialIntelligence() {
        this.toggleTool("ai", this.artificialIntelligenceTarget)
    }

    toggleGuitarTuner() {
        this.toggleTool("tuner", this.guitarTunerTarget)
    }

    toggleTool(toolName, target) {
        const isVisible = this.containerTarget.classList.contains(`${toolName}-visible`)

        if (toolName !== "metronome" && this.hasPinnedMetronomeValue && this.pinnedMetronomeValue) {
            this.hideAllToolsExceptMetronome()
            this.deactivateAllButtons()

            if (!isVisible) {
                this.containerTarget.classList.add(`${toolName}-visible`)
                this.activateButton(toolName)
                this.activeTools.add(toolName)
            } else {
                this.activeTools.delete(toolName)
            }
        } else {
            this.hideAllTools()
            this.deactivateAllButtons()

            if (!isVisible) {
                this.containerTarget.classList.add(`${toolName}-visible`)
                this.activateButton(toolName)
                this.activeTools.add(toolName)
            } else {
                this.activeTools.delete(toolName)
            }
        }

        this.updateContainerVisibility()
        this.logger.debug(`${toolName} visibility toggled. Is now visible: ${!isVisible}`)
        this.logger.info(`Current active tools: ${Array.from(this.activeTools).join(', ')}`)
    }

    hideAllTools() {
        this.containerTarget.classList.remove("settings-visible", "metronome-visible", "tuner-visible", "ai-visible")
    }

    hideAllToolsExceptMetronome() {
        this.containerTarget.classList.remove("settings-visible", "tuner-visible", "ai-visible")
    }

    deactivateAllButtons() {
        const buttons = this.element.querySelectorAll('[data-tool-name]')
        buttons.forEach(button => {
            const regularIcon = button.querySelector('.tool-icon')
            const activeIcon = button.querySelector('.tool-icon-active')
            if (regularIcon && activeIcon) {
                regularIcon.classList.remove('hidden')
                activeIcon.classList.add('hidden')
            }
        })

        if (this.hasPinnedMetronomeValue && this.pinnedMetronomeValue) {
            this.activateButton("metronome")
        }
    }

    activateButton(toolName) {
        const button = this.element.querySelector(`[data-tool-name="${toolName}"]`)
        if (button) {
            const regularIcon = button.querySelector('.tool-icon')
            const activeIcon = button.querySelector('.tool-icon-active')
            if (regularIcon && activeIcon) {
                regularIcon.classList.add('hidden')
                activeIcon.classList.remove('hidden')
            }
        }
    }

    updateContainerVisibility() {
        if (this.activeTools.size > 0) {
            this.containerTarget.classList.add('tool-visible')
        } else if (!(this.hasPinnedMetronomeValue && this.pinnedMetronomeValue)) {
            this.containerTarget.classList.remove('tool-visible')
        }
    }
}