import {Controller} from "@hotwired/stimulus"
import BaseController from "../utils/base_controller"
/**
 * SoundfilePlayer Controller
 * A Stimulus controller that manages audio playback with looping capabilities.
 * Integrates with Plyr for enhanced media controls.
 */
export default class SoundfilePlayerController extends BaseController {
    // Controller Configuration
    static targets = [
        "player", "speedControl", "currentSpeed",
        // Looping-specific targets
        "loopToggle", "startMinutes", "startSeconds", "endMinutes", "endSeconds",
        "progressBar", "loopIndicator", "startHandle", "endHandle"
    ]

    static values = {
        playerClass: String,
        debug: {type: Boolean, default: false},
        loadPath: String,
        savePath: String,
        atomId: String,
        scheduleEntryId: String,
        looping: {type: Boolean, default: false}
    }

    /**
     * Lifecycle Methods
     */
    connect() {
        this.initializing = true
        this.debugLog("SoundfilePlayer Controller Connected")

        // Create a debounced save specifically for drag operations
        this.debouncedDragSave = this.debounce(this.saveSettings.bind(this), 500)

        this.setupAudio()
        this.setupPlyr()
        this.setupSpeedControl()

        if (this.loopingValue) {
            this.initializeLoopValues()
            this.setupDropdowns()
            this.setupDragAndDrop()
        }

        this.initializeDefaultValues()
        this.setupEventListeners()
        this.loadSettings().then(() => {
            this.initializing = false
        })
    }

    disconnect() {
        if (this.player) {
            this.player.stop()
            this.player.destroy()
            this.player = null
        }

        this.removeEventListeners()
        if (this.loopingValue) {
            this.removeDragAndDropListeners()
        }
    }

    /**
     * Utility Functions
     */
    debounce(func, wait) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => func.apply(this, args), wait)
        }
    }

    /**
     * Core Setup Methods
     */
    initializeDefaultValues() {
        this.volume = 0.75
        this.speed = 1.0
    }

    setupAudio() {
        this.audio = document.getElementById(this.playerClassValue)
        if (!this.audio) {
            this.debugLog(`Audio element with id '${this.playerClassValue}' not found`, "warn")
        }
    }

    setupPlyr() {
        if (this.hasPlayerTarget) {
            try {
                this.player = new Plyr(this.playerTarget, this.plyrOptions)

                // Explicitly set initial values
                this.player.speed = 1.0
                this.player.volume = 0.75

                // Update UI to match
                if (this.hasSpeedControlTarget) {
                    this.speedControlTarget.value = 1.0
                }
                if (this.hasCurrentSpeedTarget) {
                    this.currentSpeedTarget.innerText = '1.0'
                }

                // Set up event listeners
                this.player.on('play', this.onPlay.bind(this))
                this.player.on('ratechange', this.onRateChange.bind(this))
                this.player.on('volumechange', this.onVolumeChange.bind(this))
                if (this.loopingValue) {
                    this.player.on('ended', this.onPlayerEnded.bind(this))
                }

                this.debugLog("Plyr initialized successfully")
            } catch (error) {
                this.debugLog(`Error initializing Plyr: ${error}`, "error")
            }
        }
    }

    setupSpeedControl() {
        if (this.hasSpeedControlTarget) {
            this.speedControlTarget.addEventListener('change', this.changeSpeed.bind(this))
        }
    }

    /**
     * Settings Management
     */
    async loadSettings() {
        try {
            if (!this.player || !this.hasLoadPathValue) return

            const response = await fetch(this.loadPathValue)
            if (!response.ok) return

            const settings = await response.json()
            const settingsData = settings.playback_settings || settings

            // Load volume and speed
            if (settingsData?.volume != null) {
                this.player.volume = Math.min(1, Math.max(0, settingsData.volume))
            }

            if (settingsData?.speed != null) {
                const savedSpeed = Math.min(2, Math.max(0.5, settingsData.speed))
                this.player.speed = savedSpeed

                if (this.hasSpeedControlTarget) {
                    this.speedControlTarget.value = savedSpeed
                }

                if (this.hasCurrentSpeedTarget) {
                    this.currentSpeedTarget.innerText = savedSpeed.toFixed(1)
                }
            }

            // Only load loop settings if the controls exist
            if (this.hasLoopToggleTarget && this.audio) {
                // Wait for audio metadata if needed
                if (!this.audio.duration) {
                    await new Promise(resolve => {
                        this.audio.addEventListener('loadedmetadata', resolve, {once: true})
                    })
                }

                if (settingsData?.loop_enabled != null) {
                    this.loopToggleTarget.checked = settingsData.loop_enabled
                    this.audio.loop = settingsData.loop_enabled
                }

                if (settingsData?.loop_start != null) {
                    this.loopStart = Math.max(0, Math.min(settingsData.loop_start, this.audio.duration))
                }

                if (settingsData?.loop_end != null) {
                    this.loopEnd = Math.min(settingsData.loop_end, this.audio.duration)
                }

                if (this.hasProgressBarTarget && this.hasLoopIndicatorTarget &&
                    this.hasStartHandleTarget && this.hasEndHandleTarget) {
                    this.validateLoopLength()
                    this.updateLoopIndicator()
                    this.updateLoopTimeDisplay()
                }
            }
            this.debugLog('Settings loaded successfully')
        } catch (error) {
            this.debugLog('Error loading settings:', error)
        }
    }

    async saveSettings() {
        if (!this.hasSavePathValue) return

        const settings = {
            playback_settings: {
                volume: this.player.volume,
                speed: this.player.speed,
                timestamp: new Date().toISOString()
            }
        }

        if (this.hasLoopToggleTarget) {
            settings.playback_settings.loop_enabled = this.loopToggleTarget.checked
            settings.playback_settings.loop_start = this.loopStart
            settings.playback_settings.loop_end = this.loopEnd
        }

        try {
            const response = await fetch(this.savePathValue, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify(settings)
            })

            if (!response.ok) throw new Error('Failed to save settings')

            this.debugLog('hasScheduleEntryIdValue : '+ this.hasScheduleEntryIdValue)
            this.debugLog('scheduleEntryIdValue : '+ this.scheduleEntryIdValue)
            // After successfully saving settings, refresh the mini player
            // After successfully saving settings, refresh the mini player
            if (this.hasScheduleEntryIdValue) {
                const refreshUrl = `/media/soundfile_player/${this.scheduleEntryIdValue}/refresh_mini_player`
                const refreshResponse = await fetch(refreshUrl, {
                    headers: {
                        'Accept': 'text/vnd.turbo-stream.html',
                        'Turbo-Frame': `mini-player-${this.scheduleEntryIdValue}`  // Add this line
                    }
                })

                if (!refreshResponse.ok) {
                    throw new Error('Failed to refresh mini player')
                }

                const responseText = await refreshResponse.text()
                console.log('Refresh response:', responseText)  // Debug log

                // Manually process the Turbo Stream if needed
                Turbo.renderStreamMessage(responseText)
            }

            this.debugLog('Settings saved and mini player refreshed successfully')
        } catch (error) {
            this.debugLog('Error saving settings:', error)
        }
    }

    /**
     * Event Management
     */
    setupEventListeners() {
        if (this.audio) {
            this.audio.addEventListener('loadedmetadata', this.onAudioLoaded.bind(this))
            if (this.loopingValue) {
                this.audio.addEventListener('timeupdate', this.onTimeUpdate.bind(this))
            }
        }

        if (this.player) {
            this.player.on('ratechange', this.onRateChange.bind(this))
            this.player.on('play', this.onPlay.bind(this))
            this.player.on('volumechange', this.onVolumeChange.bind(this))
            if (this.loopingValue) {
                this.player.on('ended', this.onPlayerEnded.bind(this))
            }
        }

        this.setupLoopEventListeners()
    }

    setupLoopEventListeners() {
        if (!this.loopingValue || !this.hasLoopToggleTarget) return

        // Store bound event handlers
        this.boundLoopToggleChange = this.onLoopToggleChange.bind(this)
        this.boundStartMinutesChange = this.onStartMinutesChange.bind(this)
        this.boundStartSecondsChange = this.onStartSecondsChange.bind(this)
        this.boundEndMinutesChange = this.onEndMinutesChange.bind(this)
        this.boundEndSecondsChange = this.onEndSecondsChange.bind(this)

        // Add event listeners using stored bound functions
        this.loopToggleTarget.addEventListener('change', this.boundLoopToggleChange)
        this.startMinutesTarget.addEventListener('change', this.boundStartMinutesChange)
        this.startSecondsTarget.addEventListener('change', this.boundStartSecondsChange)
        this.endMinutesTarget.addEventListener('change', this.boundEndMinutesChange)
        this.endSecondsTarget.addEventListener('change', this.boundEndSecondsChange)
    }

    removeEventListeners() {
        if (this.audio) {
            this.audio.removeEventListener('loadedmetadata', this.onAudioLoaded.bind(this))
            if (this.loopingValue) {
                this.audio.removeEventListener('timeupdate', this.onTimeUpdate.bind(this))
            }
        }

        if (this.player) {
            ['ratechange', 'play', 'volumechange', 'ended'].forEach(event => {
                this.player.off(event)
            })
        }

        this.removeLoopEventListeners()
    }

    removeLoopEventListeners() {
        if (!this.loopingValue || !this.hasLoopToggleTarget) return

        this.loopToggleTarget.removeEventListener('change', this.boundLoopToggleChange)
        this.startMinutesTarget.removeEventListener('change', this.boundStartMinutesChange)
        this.startSecondsTarget.removeEventListener('change', this.boundStartSecondsChange)
        this.endMinutesTarget.removeEventListener('change', this.boundEndMinutesChange)
        this.endSecondsTarget.removeEventListener('change', this.boundEndSecondsChange)
    }

    /**
     * Core Event Handlers
     */
    onAudioLoaded() {
        this.debugLog(`Audio loaded, duration: ${this.audio.duration}`)

        if (this.loopingValue) {
            this.setupDropdowns()
            this.updateLoop()
        }
    }

    onVolumeChange() {
        if (this.player && !this.initializing) {
            clearTimeout(this.volumeSaveTimeout)
            this.volumeSaveTimeout = setTimeout(() => this.saveSettings(), 500)
        }
    }

    onRateChange() {
        if (this.hasCurrentSpeedTarget && this.player) {
            this.currentSpeedTarget.innerText = this.player.speed.toFixed(1)
            this.speedControlTarget.value = this.player.speed
        }

        if (this.player && !this.initializing) {
            clearTimeout(this.speedSaveTimeout)
            this.speedSaveTimeout = setTimeout(() => this.saveSettings(), 500)
        }
    }

    changeSpeed(event) {
        if (this.player) {
            this.player.speed = parseFloat(event.target.value)
        }
    }

    onPlay() {
        document.dispatchEvent(new CustomEvent('plyr:play'))
    }

    /**
     * Loop Management
     */
    initializeLoopValues() {
        if (!this.loopingValue) return

        this.loopStart = 0
        this.loopEnd = 0
        this.minLoopLength = 5 // Minimum loop length in seconds
    }

    setupDropdowns() {
        if (!this.loopingValue || !this.audio) return

        const duration = Math.floor(this.audio.duration)
        const maxMinutes = Math.floor(duration / 60)
        const maxSeconds = duration % 60

        this.loopEnd = duration

        this.populateDropdown(this.startMinutesTarget, maxMinutes)
        this.populateDropdown(this.startSecondsTarget, 59)
        this.populateDropdown(this.endMinutesTarget, maxMinutes)
        this.populateEndSecondsDropdown(maxMinutes, maxSeconds)

        this.endMinutesTarget.value = maxMinutes
        this.endSecondsTarget.value = maxSeconds
    }

    populateDropdown(dropdown, max) {
        dropdown.innerHTML = ''
        for (let i = 0; i <= max; i++) {
            const option = document.createElement('option')
            option.value = i
            option.textContent = i.toString().padStart(2, '0') +
                (dropdown.id.includes('Minutes') ? ' min' : ' sec')
            dropdown.appendChild(option)
        }
    }

    populateEndSecondsDropdown(selectedMinutes, maxSeconds) {
        const duration = this.audio.duration
        const maxPossibleSeconds = selectedMinutes === Math.floor(duration / 60)
            ? maxSeconds
            : 59
        this.populateDropdown(this.endSecondsTarget, maxPossibleSeconds)
    }

    validateLoopLength() {
        if (!this.hasLoopToggleTarget || !this.audio) return

        if (this.loopEnd - this.loopStart < this.minLoopLength) {
            if (this.loopStart + this.minLoopLength > this.audio.duration) {
                this.loopStart = Math.max(0, this.audio.duration - this.minLoopLength)
            }
            this.loopEnd = Math.min(this.loopStart + this.minLoopLength, this.audio.duration)
        }

        this.loopStart = Math.max(0, this.loopStart)
        this.loopEnd = Math.min(this.loopEnd, this.audio.duration)

        if (this.loopToggleTarget.checked && this.player) {
            this.player.currentTime = this.loopStart
        }

        if (this.hasProgressBarTarget && this.hasLoopIndicatorTarget &&
            this.hasStartHandleTarget && this.hasEndHandleTarget) {
            this.updateLoopIndicator()
            this.updateLoopTimeDisplay()
        }

        if (!this.initializing) {
            // Use different save strategies based on context
            if (this.dragging) {
                this.debouncedDragSave()
            } else {
                // For direct input changes, save immediately
                this.saveSettings()
            }
        }
    }

    updateLoop() {
        if (!this.loopingValue || !this.audio) return

        this.audio.loop = this.loopToggleTarget.checked
        if (this.audio.loop) {
            this.audio.currentTime = Number(this.loopStart)
        }
    }

    updateLoopTimeDisplay() {
        if (!this.loopingValue) return

        this.startMinutesTarget.value = Math.floor(this.loopStart / 60)
        this.startSecondsTarget.value = Math.floor(this.loopStart % 60)
        this.endMinutesTarget.value = Math.floor(this.loopEnd / 60)

        this.populateEndSecondsDropdown(Math.floor(this.loopEnd / 60), Math.floor(this.audio.duration % 60))
        this.endSecondsTarget.value = Math.floor(this.loopEnd % 60)
    }

    /**
     * Loop Event Handlers
     */
    onPlayerEnded() {
        if (this.loopingValue && this.loopToggleTarget.checked && this.player) {
            this.player.currentTime = this.loopStart
            this.player.play()
        }
    }

    onTimeUpdate() {
        if (this.loopingValue && this.loopToggleTarget.checked && this.audio) {
            if (this.audio.currentTime >= this.loopEnd) {
                this.audio.currentTime = this.loopStart
                this.audio.play()
            }
        }
    }

    onLoopToggleChange() {
        this.updateLoop()
        if (!this.initializing) {
            // Toggle changes should save immediately
            this.saveSettings()
        }
    }

    onStartMinutesChange(event) {
        this.loopStart = parseInt(event.target.value) * 60 + parseInt(this.startSecondsTarget.value)
        this.validateLoopLength()
    }

    onStartSecondsChange(event) {
        this.loopStart = parseInt(this.startMinutesTarget.value) * 60 + parseInt(event.target.value)
        this.validateLoopLength()
    }

    onEndMinutesChange(event) {
        const selectedMinutes = parseInt(event.target.value)
        const maxSeconds = selectedMinutes === Math.floor(this.audio.duration / 60)
            ? Math.floor(this.audio.duration % 60)
            : 59
        this.populateEndSecondsDropdown(selectedMinutes, maxSeconds)
        this.updateLoopEnd()
    }

    onEndSecondsChange() {
        this.updateLoopEnd()
    }

    updateLoopEnd() {
        const endMinutes = parseInt(this.endMinutesTarget.value)
        const endSeconds = parseInt(this.endSecondsTarget.value)
        this.loopEnd = Math.min(endMinutes * 60 + endSeconds, this.audio.duration)
        this.validateLoopLength()
    }

    /**
     * Drag and Drop Loop Controls
     */
    setupDragAndDrop() {
        if (!this.loopingValue) return
        if (this.hasProgressBarTarget && this.hasStartHandleTarget && this.hasEndHandleTarget) {
            const options = {passive: false}

            this.boundStartDragging = this.startDragging.bind(this)
            this.boundDrag = this.drag.bind(this)
            this.boundStopDragging = this.stopDragging.bind(this)

            ;['mousedown', 'touchstart'].forEach((eventType) => {
                this.startHandleTarget.addEventListener(eventType, this.boundStartDragging, options)
                this.endHandleTarget.addEventListener(eventType, this.boundStartDragging, options)
            })

            ;['mousemove', 'touchmove'].forEach((eventType) => {
                document.addEventListener(eventType, this.boundDrag, options)
            })

            ;['mouseup', 'touchend', 'touchcancel'].forEach((eventType) => {
                document.addEventListener(eventType, this.boundStopDragging, options)
            })
        }
    }

    removeDragAndDropListeners() {
        if (!this.loopingValue) return

        try {
            ;['mousedown', 'touchstart'].forEach(eventType => {
                this.safeRemoveEventListener(this.startHandleTarget, eventType, this.boundStartDragging)
                this.safeRemoveEventListener(this.endHandleTarget, eventType, this.boundStartDragging)
            })

            ;['mousemove', 'touchmove'].forEach(eventType => {
                this.safeRemoveEventListener(document, eventType, this.boundDrag)
            })

            ;['mouseup', 'touchend', 'touchcancel'].forEach(eventType => {
                this.safeRemoveEventListener(document, eventType, this.boundStopDragging)
            })
        } catch (error) {
            this.debugLog(`Error removing drag and drop listeners: ${error.message}`, "error")
        }
    }

    safeRemoveEventListener(element, eventType, listener) {
        if (element && typeof element.removeEventListener === 'function' && typeof listener === 'function') {
            element.removeEventListener(eventType, listener)
        }
    }

    startDragging(event) {
        const handle = event.target.classList.contains('start-handle') ? 'start' : 'end'
        event.preventDefault()
        this.dragging = handle
        this.startX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
        this.startLeft = parseFloat(this[`${handle}HandleTarget`].style.left) || 0
        document.body.style.userSelect = 'none'
    }

    drag(event) {
        if (!this.dragging) return
        event.preventDefault()

        const clientX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
        const progressBarRect = this.progressBarTarget.getBoundingClientRect()
        const deltaX = clientX - progressBarRect.left
        const newLeft = Math.max(0, Math.min(100, (deltaX / progressBarRect.width) * 100))

        this[`${this.dragging}HandleTarget`].style.left = `${newLeft}%`

        const newTime = (newLeft / 100) * this.audio.duration
        if (this.dragging === 'start') {
            this.loopStart = Math.min(newTime, this.loopEnd - 1)
        } else {
            this.loopEnd = Math.max(newTime, this.loopStart + 1)
        }

        this.validateLoopLength()
        this.updateLoopIndicator()
        this.updateLoopTimeDisplay()
    }

    stopDragging() {
        if (this.dragging) {
            // Force an immediate save when dragging stops
            this.saveSettings()
        }
        this.dragging = null
        document.body.style.userSelect = ''
    }

    updateLoopIndicator() {
        if (!this.loopingValue) return

        const startPercentage = (this.loopStart / this.audio.duration) * 100
        const endPercentage = (this.loopEnd / this.audio.duration) * 100
        this.loopIndicatorTarget.style.left = `${startPercentage}%`
        this.loopIndicatorTarget.style.width = `${endPercentage - startPercentage}%`
        this.startHandleTarget.style.left = `${startPercentage}%`
        this.endHandleTarget.style.left = `${endPercentage}%`
    }

    /**
     * Utility Methods
     */
    get plyrOptions() {
        return {
            preload: 'metadata',
            controls: [
                'play-large', 'restart', 'play', 'progress', 'current-time',
                'duration', 'mute', 'volume', 'settings'
            ],
            speed: {
                selected: 1,
                options: [0.7, 0.8, 0.9, 1, 1.1, 1.2]
            },
            volume: 0.75
        }
    }

    debugLog(message, level = 'debug') {
        if (this.debugValue) {
            console[level](`SoundfilePlayer: ${message}`)
        }
    }
}