import BaseController from "../utils/base_controller"

export default class LoadingAnimationsDropdownChange extends BaseController {
    static values = {
        spinnerId: String,
        frameId: String
    }

    connect() {
        super.connect()
        this.logger.debug("Dropdown Change Controller connected")
        this.logger.debug(`Spinner ID: ${this.spinnerIdValue}`)
        this.logger.debug(`Frame ID: ${this.frameIdValue}`)

        this.bindEvents()
    }

    disconnect() {
        this.unbindEvents()
        this.logger.debug("Dropdown Change Controller disconnected")
        super.disconnect()
    }

    bindEvents() {
        this.handleFrameRender = this.handleFrameRender.bind(this)
    }

    unbindEvents() {
        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.removeEventListener('turbo:frame-render', this.handleFrameRender)
        }
    }

    handleChange(event) {
        this.logger.debug('Dropdown value changed, submitting form')
        event.target.form.requestSubmit()
    }

    handleSubmit(event) {
        this.logger.debug('Form submitted, showing spinner')
        this.showSpinner()

        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.addEventListener('turbo:frame-render', this.handleFrameRender, { once: true })
        } else {
            this.logger.warn(`Frame with id '${this.frameIdValue}' not found`)
        }
    }

    handleFrameRender(event) {
        this.logger.debug('Frame rendered, hiding spinner')
        this.hideSpinner()
    }

    showSpinner() {
        this.logger.debug('showSpinner called')
        const spinner = this.getSpinnerElement()
        if (spinner) {
            spinner.classList.remove('hidden')
            this.logger.debug("Spinner shown")
        } else {
            this.logger.error(`Spinner with id '${this.spinnerIdValue}' not found`)
        }
    }

    hideSpinner() {
        this.logger.debug('hideSpinner called')
        const spinner = this.getSpinnerElement()
        if (spinner) {
            spinner.classList.add('hidden')
            this.logger.debug("Spinner hidden")
        } else {
            this.logger.error(`Spinner with id '${this.spinnerIdValue}' not found`)
        }
    }

    getSpinnerElement() {
        if (!this.spinnerIdValue) {
            this.logger.error("Spinner ID is not set")
            return null
        }
        const spinner = document.getElementById(this.spinnerIdValue)
        if (!spinner) {
            this.logger.error(`Spinner element with id '${this.spinnerIdValue}' not found in the DOM`)
        }
        return spinner
    }
}