import { PLAYER_CONFIG } from '../constants/player_config'
import { EVENTS } from '../constants/events'
import { TimeValidationService } from './time_validation_service'

export class TimeManager {
    constructor(player, logger) {
        this.player = player
        this.logger = logger
        this.startTime = 0
        this.endTime = 15
        this.timeControlsElement = null
        this.videoDuration = null
        this.timeValidator = null
    }

    initialize(containerElement) {
        this.timeControlsElement = containerElement.querySelector('[data-controller="youtube--player-time-controls"]')

        this.timeValidator = new TimeValidationService(this.logger, {
            minLoopLength: PLAYER_CONFIG.MINIMUM_LOOP_LENGTH
        })

        if (this.timeControlsElement) {
            this.loadInitialTimes()
        }

        return {
            startTime: this.startTime,
            endTime: this.endTime
        }
    }

    loadInitialTimes() {
        const startMinutes = parseInt(this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="startMinutes"]').value) || 0
        const startSeconds = parseInt(this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="startSeconds"]').value) || 0
        const endMinutes = parseInt(this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="endMinutes"]').value) || 0
        const endSeconds = parseInt(this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="endSeconds"]').value) || 0

        const rawStartTime = (startMinutes * 60) + startSeconds
        const rawEndTime = (endMinutes * 60) + endSeconds

        try {
            const { startTime, endTime } = this.timeValidator.adjustTimeRange(rawStartTime, rawEndTime)
            this.startTime = startTime
            this.endTime = endTime

            if (rawStartTime !== startTime || rawEndTime !== endTime) {
                this.updateTimeControls(startTime, endTime)
            }
        } catch (error) {
            this.logger.error('Invalid initial times:', error)
            // Fallback to defaults
            this.startTime = 0
            this.endTime = Math.max(PLAYER_CONFIG.MINIMUM_LOOP_LENGTH, 15)
            this.updateTimeControls(this.startTime, this.endTime)
        }

        this.logger.info('Initial times loaded:', { startTime: this.startTime, endTime: this.endTime })
    }

    handleTimeUpdate(newStartTime, newEndTime) {
        if (!this.videoDuration && this.player) {
            this.videoDuration = this.player.getDuration()
            this.timeValidator = new TimeValidationService(this.logger, {
                minLoopLength: PLAYER_CONFIG.MINIMUM_LOOP_LENGTH,
                videoDuration: this.videoDuration
            })
        }

        try {
            const { startTime, endTime } = this.timeValidator.adjustTimeRange(newStartTime, newEndTime)

            this.startTime = startTime
            this.endTime = endTime

            if (this.timeControlsElement) {
                this.updateTimeControls(startTime, endTime)
            }

            this.logger.info('Times updated:', {
                startTime,
                endTime,
                videoDuration: this.videoDuration,
                loopLength: endTime - startTime
            })
        } catch (error) {
            this.logger.error('Failed to update times:', error)
        }
    }

    updateTimeControls(startTime, endTime) {
        const startMinutesElement = this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="startMinutes"]')
        const startSecondsElement = this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="startSeconds"]')
        const endMinutesElement = this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="endMinutes"]')
        const endSecondsElement = this.timeControlsElement.querySelector('[data-youtube--player-time-controls-target="endSeconds"]')

        if (startMinutesElement && startSecondsElement) {
            startMinutesElement.value = Math.floor(startTime / 60)
            startSecondsElement.value = Math.floor(startTime % 60)
        }

        if (endMinutesElement && endSecondsElement) {
            endMinutesElement.value = Math.floor(endTime / 60)
            endSecondsElement.value = Math.floor(endTime % 60)
        }
    }

    handlePlayerReady() {
        this.videoDuration = this.player.getDuration()

        this.timeValidator = new TimeValidationService(this.logger, {
            minLoopLength: PLAYER_CONFIG.MINIMUM_LOOP_LENGTH,
            videoDuration: this.videoDuration
        })

        this.updateTimeDropdowns()

        try {
            const { startTime, endTime } = this.timeValidator.adjustTimeRange(this.startTime, this.endTime)
            if (startTime !== this.startTime || endTime !== this.endTime) {
                this.startTime = startTime
                this.endTime = endTime
                this.updateTimeControls(startTime, endTime)
            }
        } catch (error) {
            this.logger.error('Failed to adjust times after player ready:', error)
        }
    }

    updateTimeDropdowns() {
        if (!this.timeControlsElement || !this.videoDuration) return

        const durationMinutes = Math.floor(this.videoDuration / 60)

        const minutesSelects = this.timeControlsElement.querySelectorAll('[data-youtube--player-time-controls-target="startMinutes"], [data-youtube--player-time-controls-target="endMinutes"]')
        minutesSelects.forEach(select => {
            const currentValue = select.value
            select.innerHTML = ''

            for (let i = 0; i <= durationMinutes; i++) {
                const option = document.createElement('option')
                option.value = i
                option.text = i
                select.appendChild(option)
            }

            select.value = currentValue <= durationMinutes ? currentValue : 0
        })

        const secondsSelects = this.timeControlsElement.querySelectorAll('[data-youtube--player-time-controls-target="startSeconds"], [data-youtube--player-time-controls-target="endSeconds"]')
        secondsSelects.forEach(select => {
            const currentValue = select.value
            select.innerHTML = ''

            for (let i = 0; i < 60; i++) {
                const option = document.createElement('option')
                option.value = i
                option.text = i
                select.appendChild(option)
            }

            select.value = currentValue
        })
    }

    getCurrentTimes() {
        return {
            startTime: this.startTime,
            endTime: this.endTime
        }
    }

    cleanup() {
        this.timeControlsElement = null
        this.videoDuration = null
        this.timeValidator = null
    }
}