// search_type_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["container"]

    connect() {
        const savedType = localStorage.getItem('searchType') || 'all'
        this.updateSection(savedType)
    }

    toggleAll(event) {
        event.preventDefault()
        this.updateSection('all')
    }

    toggleScales(event) {
        event.preventDefault()
        this.updateSection('scales')
    }

    toggleArpeggios(event) {
        event.preventDefault()
        this.updateSection('arpeggios')
    }

    updateSection(sectionName) {
        if (!this.hasContainerTarget) return

        // Hide all sections first
        const allSections = this.containerTarget.children
        Array.from(allSections).forEach(section => {
            section.classList.add('hidden')
        })

        // Show the selected section
        const selectedSection = this.containerTarget.querySelector(`[data-search-type-section="${sectionName}"]`)
        if (selectedSection) {
            selectedSection.classList.remove('hidden')
        }

        // Update button states
        const buttons = this.element.querySelectorAll('[data-action*="search-type#toggle"]')
        buttons.forEach(button => {
            const actionName = button.dataset.action
            const buttonType = actionName.match(/toggle(\w+)/)[1].toLowerCase()
            button.dataset.state = buttonType === sectionName ? 'active' : 'inactive'
        })

        localStorage.setItem('searchType', sectionName)
    }
}