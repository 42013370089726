export class GlobalLogger {
    static debug = false
    static logLevels = new Set(['error', 'warn', 'info', 'debug'])

    constructor(context) {
        this.context = context
        this.identifier = context.identifier
        this.isDebug = context.constructor.debug ?? GlobalLogger.debug

        this.log = (message, value, level = 'debug') => {
            if (!this.isDebug || !GlobalLogger.logLevels.has(level)) return

            if (value === undefined) {
                console[level](`[${this.identifier}] ${message}`)
            } else {
                const formattedValue = this.formatValue(value)
                console[level](`[${this.identifier}] ${message}`, formattedValue)
            }
        }

        this.error = (message, value) => this.log(message, value, 'error')
        this.warn = (message, value) => this.log(message, value, 'warn')
        this.info = (message, value) => this.log(message, value, 'info')
        this.debug = (message, value) => this.log(message, value, 'debug')
    }

    formatValue(value) {
        if (value === null) return 'null'
        if (value === undefined) return 'undefined'

        if (typeof value === 'object') {
            try {
                return value instanceof Error ? value.message : value
            } catch (e) {
                return value
            }
        }

        return value
    }
}