// controllers/youtube/multi_player_switcher_controller.js
import BaseController from "../utils/base_controller"
import { CSRFService } from "./services/csrf_service"

export default class extends BaseController {
    static targets = ["button"]
    static values = {
        videoId: String,
        atomType: String,
        atomId: String,
        activeVideoId: String
    }

    connect() {
        this.logger.info('Connecting multi-player switcher')
        if (this.activeVideoIdValue) {
            this.updateActiveState()
        }
    }

    disconnect() {
        this.logger.info('Disconnecting multi-player switcher')
    }

    switchVideo(event) {
        const videoId = event.currentTarget.dataset.videoId
        const atomType = this.atomTypeValue
        const atomId = this.atomIdValue

        // Update state immediately
        this.activeVideoIdValue = videoId
        this.updateActiveState()

        // Make API call
        const token = document.querySelector('[name="csrf-token"]')?.content
        fetch(`/internal_apis/youtube_settings/update_active_video/${atomType}/${atomId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            },
            body: JSON.stringify({ video_id: videoId })
        })

        // Dispatch event
        this.dispatch('videoSwitch', { detail: { videoId } })
    }

    updateActiveState() {
        this.buttonTargets.forEach(button => {
            button.dataset.state =
                button.dataset.videoId === this.activeVideoIdValue ? "active" : "inactive"
        })
    }
}