// services/time_validation_service.js
export class TimeValidationService {
    constructor(logger, options = {}) {
        this.logger = logger
        this.minLoopLength = options.minLoopLength || 5
        this.videoDuration = options.videoDuration || null
    }

    validateTimeRange(startTime, endTime) {
        // Returns a normalized, valid time range or throws if impossible
        if (startTime >= endTime) {
            this.logger.warn('Invalid time range - start >= end:', { startTime, endTime })
            throw new Error('Start time must be less than end time')
        }

        if (endTime - startTime < this.minLoopLength) {
            this.logger.warn('Loop length too short:', { startTime, endTime })
            throw new Error(`Loop length must be at least ${this.minLoopLength} seconds`)
        }

        if (this.videoDuration) {
            if (startTime < 0 || endTime > this.videoDuration) {
                this.logger.warn('Times out of video bounds:', { startTime, endTime, duration: this.videoDuration })
                throw new Error('Times must be within video duration')
            }
        }

        return { startTime, endTime }
    }

    adjustTimeRange(startTime, endTime) {
        // Attempts to fix invalid ranges by adjusting times
        try {
            return this.validateTimeRange(startTime, endTime)
        } catch (e) {
            // Try to fix the range
            if (startTime >= endTime) {
                endTime = startTime + this.minLoopLength
            }

            if (this.videoDuration) {
                if (endTime > this.videoDuration) {
                    endTime = this.videoDuration
                    startTime = Math.max(0, endTime - this.minLoopLength)
                }
                if (startTime < 0) {
                    startTime = 0
                    endTime = Math.min(this.videoDuration, startTime + this.minLoopLength)
                }
            }

            return this.validateTimeRange(startTime, endTime)
        }
    }

    getValidEndTimeRange(startTime) {
        // Returns min/max valid end times for a given start time
        const minEnd = startTime + this.minLoopLength
        const maxEnd = this.videoDuration || Infinity
        return { minEnd, maxEnd }
    }

    getValidStartTimeRange(endTime) {
        // Returns min/max valid start times for a given end time
        const minStart = 0
        const maxStart = endTime - this.minLoopLength
        return { minStart, maxStart }
    }
}