import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["dialog"]

    connect() {
        super.connect()
        // Add click handlers to close dialogs when clicking outside
        this.dialogTargets.forEach(dialog => {
            dialog.addEventListener('click', (event) => {
                if (event.target === dialog) {
                    this.hide(event)
                }
            })

            // Handle ESC key
            dialog.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    this.hide(event)
                }
            })
        })
    }

    disconnect() {
        super.disconnect()
        // Clean up event listeners
        this.dialogTargets.forEach(dialog => {
            dialog.removeEventListener('click')
            dialog.removeEventListener('keydown')
        })
    }

    show(event) {
        event.preventDefault()

        // Find the dialog - either by target attribute or by finding the closest dialog
        const dialogTarget = event.currentTarget.getAttribute('data-dialog-target')
        let dialog

        if (dialogTarget) {
            dialog = document.getElementById(dialogTarget)
        } else {
            dialog = event.currentTarget.closest('dialog')
        }

        if (dialog && typeof dialog.showModal === 'function') {
            this.logger.debug("Showing dialog:", dialogTarget)
            dialog.showModal()
        } else {
            this.logger.error("Dialog not found or showModal not supported")
        }
    }

    hide(event) {
        event.preventDefault()

        // Find the closest dialog element
        const dialog = event.target.closest('dialog')
        if (dialog) {
            this.logger.debug("Hiding dialog")
            dialog.close()
        }
    }
}