// app/javascript/controllers/tabs_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log("Homepage Tabs controller connected")
    }

    disconnect() {
        console.log("Homepage Tabs controller disconnected")
    }

    navigate(event) {
        console.log("Navigate called with value:", event.target.value)
        Turbo.visit(event.target.value)
    }
}