// app/javascript/controllers/youtube/multi_player_state_controller.js
import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static debug = false
    static targets = ["player"]
    static values = {
        activeVideoId: String
    }

    connect() {
        this.logger.info('Connecting multi-player state controller')
        this.logger.info(`Initial active video ID: ${this.activeVideoIdValue}`)
        this.updatePlayerVisibility(this.activeVideoIdValue)
    }

    disconnect() {
        this.logger.info('Disconnecting multi-player state controller')
    }

    handleVideoSwitch(event) {
        const newActiveVideoId = event.detail.videoId
        this.logger.info(`Switching to video ID: ${newActiveVideoId}`)
        this.activeVideoIdValue = newActiveVideoId
        this.updatePlayerVisibility(newActiveVideoId)
    }

    updatePlayerVisibility(activeVideoId) {
        this.logger.info(`Updating visibility for active video ID: ${activeVideoId}`)
        this.playerTargets.forEach((player) => {
            // Log the entire dataset to see what we're getting
            this.logger.info('Player dataset:', player.dataset)

            // Try accessing the video ID directly from the attribute
            const directAttr = player.getAttribute('data-youtube--multi-player-state-video-id')
            this.logger.info(`Direct attribute value: ${directAttr}`)

            const playerVideoId = directAttr
            const isActive = playerVideoId === activeVideoId
            this.logger.info(`Player ${playerVideoId} active: ${isActive}`)

            if (isActive) {
                player.classList.remove('hidden')
            } else {
                player.classList.add('hidden')
            }
        })
    }
}