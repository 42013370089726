// controllers/youtube/constants/player_config.js
export const PLAYER_CONFIG = {
    DEFAULT_HEIGHT: '360',
    DEFAULT_WIDTH: '640',
    PLAYER_VARS: {
        controls: 1,
        rel: 0,
        autoplay: 0,
        playsinline: 1
    },
    CHECK_INTERVALS: {
        VOLUME: 1000,  // Volume check interval (1 second)
        LOOP: 250     // Loop check interval (250ms)
    },
    SEEK_DELAY: 100,  // Delay before playing after seek
    MINIMUM_LOOP_LENGTH: 5  // Minimum length for video loops in seconds
}