import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["songImages"]

    connect() {
        super.connect()
        this.logger.debug(`Initial layout: ${this.currentLayout}`)
    }

    disconnect() {
        this.logger.debug("Toggle Row Column Layout controller disconnected")
        super.disconnect()
    }

    toggleRowColumnLayout() {
        const songImages = this.songImagesTarget

        if (songImages.classList.contains('flex-row')) {
            songImages.classList.remove('flex-row')
            songImages.classList.add('flex-col')
            this.logger.debug("Layout changed to column")
        } else {
            songImages.classList.remove('flex-col')
            songImages.classList.add('flex-row')
            this.logger.debug("Layout changed to row")
        }

        this.logger.debug(`New layout: ${this.currentLayout}`)
    }

    get currentLayout() {
        return this.songImagesTarget.classList.contains('flex-row') ? 'row' : 'column'
    }
}