import { PLAYER_CONFIG } from '../constants/player_config'
import { EVENTS } from '../constants/events'

export class VolumeManager {
    constructor(player, logger, dispatcher) {
        this.player = player
        this.logger = logger
        this.dispatcher = dispatcher
        this._lastVolume = null
        this.checkInterval = null
    }

    initialize() {
        const initialVolume = this.player.getVolume()
        this._lastVolume = initialVolume
        this.startVolumeChecking()
        this.dispatchVolumeUpdate(initialVolume)
        return initialVolume
    }

    startVolumeChecking() {
        this.checkInterval = setInterval(() => {
            if (this.player) {
                const currentVolume = this.player.getVolume()
                if (this._lastVolume !== currentVolume) {
                    this.logger.info('Volume changed:', {
                        from: this._lastVolume,
                        to: currentVolume
                    })
                    this._lastVolume = currentVolume
                    this.dispatchVolumeUpdate(currentVolume)
                }
            }
        }, PLAYER_CONFIG.CHECK_INTERVALS.VOLUME)
    }

    handleVolumeChange() {
        const volume = this.player.getVolume()
        this.dispatchVolumeUpdate(volume)
    }

    dispatchVolumeUpdate(volume) {
        if (volume !== undefined) {
            this.logger.info('Volume update:', volume)
            this.dispatcher(EVENTS.VOLUME_CHANGED, { detail: { volume } })
        }
    }

    applySettings(settings) {
        if (settings?.volume !== undefined && this.player) {
            this.logger.info('Applying volume from settings:', settings.volume)
            this.player.setVolume(settings.volume)
            this._lastVolume = settings.volume
        }
    }

    cleanup() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval)
            this.checkInterval = null
        }
    }
}