import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["prompt", "conversation"]
    static values = {
        service: String,
        debug: Boolean
    }

    connect() {
        super.connect()
        this.logger.debug(`Service: ${this.serviceValue}`)
        this.boundHandleMessage = this.#handleMessage.bind(this)
        this.boundHandleError = this.#handleError.bind(this)
    }

    disconnect() {
        this.#cleanupEventSource()
        super.disconnect()
    }

    generateResponse(event) {
        event.preventDefault()
        this.logger.info("Generating response")

        this.#createLabel('You')
        this.#createMessage(this.promptTarget.value)
        this.#createLabel(this.serviceValue === 'claude' ? 'Claude' : 'ChatGPT')
        this.currentPre = this.#createMessage()

        this.#setupEventSource()

        this.logger.debug(`Prompt: "${this.promptTarget.value}"`)
        this.promptTarget.value = ""
    }

    #createLabel(text) {
        const label = document.createElement('strong')
        label.innerHTML = `${text}:`
        this.conversationTarget.appendChild(label)
        this.logger.debug(`Created label: ${text}`)
    }

    #createMessage(text = '') {
        const preElement = document.createElement('pre')
        preElement.innerHTML = text
        this.conversationTarget.appendChild(preElement)
        this.logger.debug(`Created message${text ? ': ' + text : ' (empty)'}`)
        return preElement
    }

    #setupEventSource() {
        this.#cleanupEventSource()

        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        const prompt = encodeURIComponent(this.promptTarget.value)
        const endpoint = this.serviceValue === 'claude' ? '/chat/show_claude' : '/chat/show_chatgpt'

        this.logger.debug(`Setting up EventSource for endpoint: ${endpoint}`)

        this.eventSource = new EventSource(`${endpoint}?prompt=${prompt}`, {
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })

        this.eventSource.addEventListener("message", this.boundHandleMessage)
        this.eventSource.addEventListener("error", this.boundHandleError)

        this.logger.debug("EventSource setup complete")
    }

    #handleMessage(event) {
        const parsedData = JSON.parse(event.data)
        this.currentPre.innerHTML += parsedData.message

        this.conversationTarget.scrollTop = this.conversationTarget.scrollHeight

        this.logger.debug(`Received message chunk: ${parsedData.message}`)
    }

    #handleError(event) {
        this.logger.error("EventSource error occurred")
        if (event.eventPhase === EventSource.CLOSED) {
            this.#cleanupEventSource()
        }
    }

    #cleanupEventSource() {
        if (this.eventSource) {
            this.eventSource.removeEventListener("message", this.boundHandleMessage)
            this.eventSource.removeEventListener("error", this.boundHandleError)
            this.eventSource.close()
            this.eventSource = null
            this.logger.debug("EventSource cleaned up")
        }
    }
}