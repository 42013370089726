import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["button"]
    static values = {
        atomId: Number,
        isRecording: Boolean,
        startPath: String,
        stopPath: String,
        autoStart: Boolean,
        testMode: Boolean
    }

    connect() {
        super.connect()
        this.updateButtonState()
        document.addEventListener('recording-started', this.handleGlobalRecordingStarted.bind(this))
        document.addEventListener('recording-stopped', this.handleGlobalRecordingStopped.bind(this))
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))

        if (this.autoStartValue) {
            this.startRecording(false)
        }
    }

    disconnect() {
        if (this.isRecordingValue) {
            this.stopRecording(false)
            localStorage.removeItem('previouslyRecordingAtomId')
        }
        document.removeEventListener('recording-started', this.handleGlobalRecordingStarted.bind(this))
        document.removeEventListener('recording-stopped', this.handleGlobalRecordingStopped.bind(this))
        window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this))
        super.disconnect()
    }

    toggleRecording() {
        if (this.isRecordingValue) {
            this.stopRecording(true)
        } else {
            const currentlyRecordingAtomId = this.getCurrentlyRecordingAtomId()
            if (currentlyRecordingAtomId) {
                localStorage.setItem('previouslyRecordingAtomId', currentlyRecordingAtomId.toString())
            }
            this.startRecording(true)
        }
    }

    startRecording(isUserInitiated = true) {
        // Immediately update UI
        this.isRecordingValue = true
        this.updateButtonState()

        if (isUserInitiated) {
            document.dispatchEvent(new CustomEvent('recording-started', { detail: { atomId: this.atomIdValue } }))
        }

        // Send request to server
        this.sendRecordingRequest(this.startPathValue, 'POST')
    }

    stopRecording(isUserInitiated = true) {
        // Immediately update UI
        this.isRecordingValue = false
        this.updateButtonState()

        if (isUserInitiated) {
            document.dispatchEvent(new CustomEvent('recording-stopped', { detail: { atomId: this.atomIdValue } }))
        }

        // Send request to server
        this.sendRecordingRequest(this.stopPathValue, 'POST')
    }

    sendRecordingRequest(path, method) {

        const csrfToken = this.getCsrfToken()
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken
        }

        // Get the previously recording atom ID from localStorage
        const currentlyRecordingAtomId = localStorage.getItem('previouslyRecordingAtomId')
        localStorage.removeItem('previouslyRecordingAtomId') // Clear after using

        fetch(path, {
            method: method,
            headers: headers,
            body: JSON.stringify({
                currently_recording_atom_id: currentlyRecordingAtomId ? parseInt(currentlyRecordingAtomId) : null
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then(data => {
                this.logger.info("Recording request sent successfully")
            })
            .catch(error => {
                this.logger.error("Error sending recording request: " + error)
                this.setErrorState()
            })
    }

    updateButtonState() {
        this.buttonTarget.classList.remove('recording', 'not-recording', 'error')
        if (this.isRecordingValue) {
            this.buttonTarget.classList.add('recording')
        } else {
            this.buttonTarget.classList.add('not-recording')
        }
    }

    setErrorState() {
        this.buttonTarget.classList.remove('recording', 'not-recording')
        this.buttonTarget.classList.add('error')
        // Reset error state after 3 seconds
        setTimeout(() => {
            this.updateButtonState()
        }, 3000)
    }

    handleGlobalRecordingStarted(event) {
        if (event.detail.atomId !== this.atomIdValue) {
            this.isRecordingValue = false
            this.updateButtonState()
        }
    }

    handleGlobalRecordingStopped(event) {
        if (event.detail.atomId === this.atomIdValue) {
            this.isRecordingValue = false
            this.updateButtonState()
        }
    }

    handleBeforeUnload() {
        if (this.isRecordingValue) {
            this.stopRecording(false)
            localStorage.removeItem('previouslyRecordingAtomId')
        }
    }

    getCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]')
        return metaTag ? metaTag.content : null
    }

    getCurrentlyRecordingAtomId() {
        const recordingElement = document.querySelector('.recording-button.recording');

        if (!recordingElement) return null;

        const container = recordingElement.closest('[data-animation--recording-button-atom-id-value]');
        if (!container) {
            return null;
        }

        const atomId = parseInt(container.getAttribute('data-animation--recording-button-atom-id-value'));

        return atomId;
    }
}