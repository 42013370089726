import BaseController from "../utils/base_controller"
import YouTubeAPI from "../../src/services/youtube_api"
import { PLAYER_CONFIG } from './constants/player_config'

export default class extends BaseController {
    static values = {
        videoId: String
    }

    async initializeYouTubePlayer(targetElement, options = {}) {
        if (!this.videoIdValue || !targetElement) {
            this.logger.warn('Cannot initialize player:', {
                videoId: this.videoIdValue,
                targetElement
            })
            return null
        }

        await YouTubeAPI.initialize()

        return new Promise((resolve) => {
            try {
                const player = new YT.Player(targetElement, {
                    videoId: this.videoIdValue,
                    height: PLAYER_CONFIG.DEFAULT_HEIGHT,
                    width: PLAYER_CONFIG.DEFAULT_WIDTH,
                    playerVars: PLAYER_CONFIG.PLAYER_VARS,
                    ...options
                })

                this.logger.info('Player instance created:', player)
                resolve(player)
            } catch (error) {
                this.logger.error('Error creating player:', error)
                resolve(null)
            }
        })
    }
}