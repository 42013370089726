import { Controller } from "@hotwired/stimulus"
import { GlobalLogger } from "./global_logger"

export default class BaseController extends Controller {
    constructor(context) {
        super(context)
        this.logger = new GlobalLogger(this)
    }

    connect() {
        this.logger.info(`${this.identifier} controller connected`)
    }

    disconnect() {
        this.logger.info(`${this.identifier} controller disconnected`)
    }
}